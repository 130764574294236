import { Flex, Text, Checkbox, Button, Divider, Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BasketItem } from './BasketItem'
import { useNavigate } from 'react-router-dom'
import { useDeleteCarts } from 'common/api/domains/purchase/purchaseMutation'
import {
  useGetCartIds,
  useGetCost,
} from 'common/api/domains/purchase/purchaseQuery'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  cartCostState,
  cartsState,
  selectedCartIdsState,
} from 'state/purchase/atoms'

type BasketListProps = {}

export const BasketList = ({}: BasketListProps) => {
  const navigate = useNavigate()
  const { data: cartIds, refetch: refetchCartIds } = useGetCartIds()
  const [selectedCartIds, setSelectedCartIds] =
    useRecoilState(selectedCartIdsState)
  const { mutate: deleteCarts } = useDeleteCarts()
  const [carts, setCarts] = useRecoilState(cartsState)
  const { data: fetchedCostDate, refetch: refetchCost } = useGetCost({
    products: selectedCartIds
      .filter((cartId) => !!carts[cartId])
      .map((cartId) => ({
        productId: carts[cartId].productId,
        count: carts[cartId].count,
      })),
  })
  const setCost = useSetRecoilState(cartCostState)

  const allChecked = (cartIds || []).every((cartId) =>
    selectedCartIds.includes(cartId),
  )
  const isIndeterminate =
    (cartIds || []).some((cartId) => selectedCartIds.includes(cartId)) &&
    !allChecked

  const onChangeCheckbox = () => {
    if (!cartIds) return
    if (allChecked) {
      return setSelectedCartIds([])
    }
    return setSelectedCartIds([...cartIds])
  }

  const onClickDeleteOptions = async () => {
    await deleteCarts(selectedCartIds, {
      onSuccess: () => {
        refetchCartIds()
        setCarts((prev) => {
          const newState = { ...prev }
          selectedCartIds.forEach((cartId) => {
            delete newState[cartId]
          })
          return newState
        })
      },
    })
  }

  useEffect(() => {
    if (!fetchedCostDate) return
    setCost(fetchedCostDate)
  }, [fetchedCostDate])

  useEffect(() => {
    refetchCost()
  }, [cartIds, selectedCartIds, carts])

  if (!cartIds) return null

  return (
    <Box flex={1}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={3}
        pr={5}
        pl={5}
      >
        <Flex>
          <Checkbox
            size={'lg'}
            m={1}
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={onChangeCheckbox}
          />
          <Text ml={4}>
            НИЙТ {cartIds.length}
            {/*총 ()개의 상품*/}
          </Text>
        </Flex>
        <Button variant={'link'} onClick={onClickDeleteOptions}>
          УСТГАХ
        </Button>{' '}
        {/*선택상품삭제*/}
      </Flex>
      <Divider />
      {cartIds.map((cartId) => (
        <BasketItem
          key={cartId}
          cartId={cartId}
          refetchCartIds={refetchCartIds}
        />
      ))}
      <Flex justifyContent={'center'}>
        <Button
          colorScheme="gray"
          variant={'outline'}
          size={'lg'}
          w={'352px'}
          mt={4}
          onClick={() => navigate('/goods/categories')}
        >
          ӨӨР БҮТЭЭГДЭХҮҮНИЙГ ҮЗЭХ
        </Button>{' '}
        {/*더 담으러가기*/}
      </Flex>
    </Box>
  )
}
