import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
  BreadcrumbLinkProps,
} from '@chakra-ui/react'
import React from 'react'

type BreadcrumbNavigationProps = {
  items: {
    name: string
    breadcrumbItemProps?: BreadcrumbItemProps
    breadcrumbLinkProps?: BreadcrumbLinkProps
  }[]
}

export const BreadcrumbNavigation = ({ items }: BreadcrumbNavigationProps) => {
  return (
    <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
      {items.map((item, index) => {
        return (
          <BreadcrumbItem key={index} {...item.breadcrumbItemProps}>
            <BreadcrumbLink {...item.breadcrumbLinkProps}>
              {item.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}
