import React from 'react'
import { OrderReceiptMobile } from './mobile'
import { OrderReceiptDesktop } from './desktop'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export const OrderReceipt = () => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <OrderReceiptMobile />
  return <OrderReceiptDesktop />
}
