import React from 'react'
import { useParams } from 'react-router-dom'
import { ProductDetail } from 'views/goods'

export const ProductDetailPage = () => {
  const { productId } = useParams()

  if (!productId || isNaN(+productId)) return null
  return <ProductDetail productId={+productId} />
}
