import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { UploadReceipt } from './UploadReceipt'
import { ReuploadReceipt } from './ReouploadReceipt'
import { UploadedReceipt } from './UploadedReceipt'
import { OrderStatus } from 'common/util/enum'
import { useNavigate, useParams } from 'react-router-dom'
import { MoneyText } from 'views/component/MoneyText'
import { useGetOrder } from 'common/api/domains/purchase/purchaseQuery'

export const OrderReceiptMobile = () => {
  const { orderId } = useParams()
  const { data: order, refetch } = useGetOrder(Number(orderId))
  const navigate = useNavigate()

  useEffect(() => {
    if (!orderId || isNaN(Number(orderId))) return
    refetch()
  }, [orderId])
  if (!order) return null

  return (
    <VStack w={'375px'}>
      <Text fontSize={'xl'} fontWeight={'bold'} m={4}>
        ЗАХИАЛГА ДУУССАН
      </Text>
      {/*주문완료*/}
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        backgroundColor={'#ffffff'}
        pt={1}
        pb={0}
        px={4}
      >
        <CheckCircleIcon fontSize={48} color={'#005E9E'} />
        <Text mt={4} fontSize={'md'} fontWeight={'bold'}>
          ТАНЫ ЗАХИАЛГА АМЖИЛТТАЙ ДУУСЛАА.
        </Text>{' '}
        {/*결제가 완료되었습니다.*/}
        <Text color={'#2f2f2f'} fontWeight={'bold'}>
          ЗАХИАЛГА NO. {order.orderNumber} {/*주문번호*/}
        </Text>
        <Divider my={4} borderColor={'#606060'} />
        <Flex
          mt={4}
          mb={2}
          w={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontSize={'sm'} fontWeight={'bold'}>
            НИЙТ ТӨЛБӨР
          </Text>{' '}
          {/*최종 결제 금액*/}
          <MoneyText
            amount={order.productCost + order.deliveryCost - order.discount}
            unit={'₩'}
            textProps={{
              fontWeight: 'bold',
              fontSize: 'md',
              color: '#005E9E',
            }}
          />
        </Flex>
        <Flex
          w={'100%'}
          justifyContent={'space-between'}
          flexDirection={'column'}
        >
          <Text w={'100%'} mt={3} as={'b'}>
            ТӨЛБӨР ЭЗЭМШИГЧИЙН НЭР
          </Text>{' '}
          {/*입금자명*/}
          <Text color={'#606060'}>{order.depositorName}</Text>
        </Flex>
        <Flex
          w={'100%'}
          justifyContent={'space-between'}
          flexDirection={'column'}
        >
          <Text w={'100%'} mt={5} as={'b'}>
            ТӨЛБӨР ИЛГЭЭХ ДАНС
          </Text>{' '}
          {/*입금은행*/}
          <Text color={'#606060'} mb={2}>
            {`${order.accountBankName} ${order.accountNumber} (${order.accountHolder})`}
          </Text>
        </Flex>
        {/* {order?.status === OrderStatus.PENDING && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.CHECKING && (
          <ReuploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.APPROVED && (
          <UploadedReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.REJECTED && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )}
        {order?.status === OrderStatus.CANCELED && (
          <UploadReceipt
            orderId={order.id}
            refetchOrder={async () => {
              await refetch()
            }}
          />
        )} */}
      </Flex>
      <Flex w={'100%'} p={4} pt={2} flexDirection={'column'}>
        <Text mt={4} mb={2} fontWeight={'bold'}>
          ХҮРГЭЛТ
        </Text>
        {/*배송정보*/}
        <Divider borderColor={'gray'} />
        <Grid templateColumns={'96px 1fr'} gap={4} mt={8} mb={8}>
          <GridItem color={'#505050'} fontSize={'sm'} w={100}>
            ХҮЛЭЭН АВАГЧ
          </GridItem>{' '}
          {/*수령인*/}
          <GridItem fontSize={'sm'} textAlign={'right'}>
            {order.name}
          </GridItem>
          <GridItem color={'#505050'} fontSize={'sm'}>
            ХАЯГ
          </GridItem>{' '}
          {/*주소*/}
          <GridItem
            fontSize={'sm'}
            textAlign={'right'}
          >{`[${order.postcode}] ${order.address1} ${order.address2}`}</GridItem>
          <GridItem color={'#505050'} fontSize={'sm'}>
            УТАС
          </GridItem>{' '}
          {/*연락처*/}
          <GridItem fontSize={'sm'} textAlign={'right'}>
            {order.phone}
          </GridItem>
        </Grid>
        <Text mt={8} mb={2} fontWeight={'bold'}>
          ЗАХИАЛСАН БҮТЭЭГДЭХҮҮН
        </Text>{' '}
        {/*주문상품*/}
        <Divider borderColor={'gray'} />
        {(order.orderProducts || []).map((orderProduct) => {
          return (
            <Grid
              key={orderProduct.id}
              templateColumns={'128px 1fr'}
              gap={4}
              mt={4}
            >
              <GridItem color={'#505050'} fontSize={'sm'}>
                {orderProduct.productName}
              </GridItem>
              <GridItem>
                <MoneyText
                  amount={orderProduct.price * orderProduct.count}
                  unit={'₩'}
                  suffix={`(${orderProduct.count} ${orderProduct.unit})`}
                  textProps={{ fontSize: 'sm' }}
                />
              </GridItem>
            </Grid>
          )
        })}
        <Text mt={8} mb={2} fontWeight={'bold'}>
          ТӨЛБӨРИЙН МЭДЭЭЛЭЛ
        </Text>{' '}
        {/*결제 정보*/}
        <Divider borderColor={'gray'} />
        <Grid gap={4} my={8}>
          <GridItem
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text fontSize={'md'}>БҮТЭЭГДЭХҮҮН</Text> {/*주문상품*/}
            <MoneyText
              amount={order.productCost}
              unit={'₩'}
              textProps={{ fontSize: 'md' }}
            />
          </GridItem>
          <GridItem
            gap={4}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text fontSize={'md'}>ХҮРГЭЛТ</Text> {/*배송비*/}
            <MoneyText
              amount={order.deliveryCost}
              unit={'₩'}
              textProps={{ fontSize: 'sm' }}
            />
          </GridItem>
          <GridItem
            gap={4}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text fontSize={'sm'}>ХӨНГӨЛӨЛТ</Text> {/*할인*/}
            <MoneyText
              amount={order.discount}
              unit={'₩'}
              prefix="-"
              textProps={{ fontSize: 'sm' }}
            />
          </GridItem>
        </Grid>
        <Divider borderColor={'#DFE3E9'} />
        <Flex justifyContent={'space-between'} mt={4} mb={8}>
          <Text fontWeight={'bold'}>НИЙТ ТӨЛБӨР </Text>
          {/*최종 결제금액*/}
          <MoneyText
            amount={order.productCost + order.deliveryCost - order.discount}
            unit={'₩'}
            textProps={{ fontWeight: 'bold', color: '#005E9E' }}
          />
        </Flex>
      </Flex>
      <Button
        w={'100%'}
        onClick={() => navigate('/')}
        size={'lg'}
        colorScheme="blue"
        mb={15}
      >
        ОЙЛГОЛОО
      </Button>
    </VStack>
  )
}
