import axios from 'axios'
import { createAxiosInstance } from 'common/config/axios.config'
import { CartModel, CostModel, OrderModel } from 'domain/purchase/models'
import { CostRequestDTO } from 'domain/purchase/request.dto'
import {
  CartListResponseDTO,
  CartResponseDTO,
  CostResponseDTO,
  OrderResponseDTO,
} from 'domain/purchase/response.dto'

const purchaseAxiosInstance = createAxiosInstance(true)

export function fetchCartIds(): Promise<number[]> {
  return purchaseAxiosInstance
    .get<CartListResponseDTO>(`/purchase/carts`)
    .then((response) => {
      return response.data.cartIds
    })
}

export function fetchCartIdsByProductId(productId: number): Promise<number[]> {
  return purchaseAxiosInstance
    .get<CartListResponseDTO>(`/purchase/carts/products/${productId}`)
    .then((response) => {
      return response.data.cartIds
    })
}

export function fetchCart(cartId: number): Promise<CartModel> {
  return purchaseAxiosInstance
    .get<CartResponseDTO>(`/purchase/carts/${cartId}`)
    .then((response) => {
      return new CartModel(response.data)
    })
}

export function fetchEstimatedCost(
  costRequestDto: CostRequestDTO,
): Promise<CostModel> {
  return purchaseAxiosInstance
    .get<CostResponseDTO>(`/purchase/cost`, { params: costRequestDto })
    .then((response) => {
      return new CostModel(response.data)
    })
}

export function fetchOrder(orderId: number): Promise<OrderModel> {
  return purchaseAxiosInstance
    .get<OrderResponseDTO>(`/purchase/orders/${orderId}`)
    .then((response) => {
      return new OrderModel(response.data)
    })
}
