import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React from 'react'
import { ProfileLayout } from './ProfileLayout'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { CustomInput } from 'views/component/CustomInput'
import { OrderListPanel } from 'views/component/OrderListPanel'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
const selectedStyle = {
  fontWeight: 'bold',
  color: 'black',
  borderTop: '2px solid black',
  borderBottom: 'none',
}
export function OrderList() {
  const isMobile = useRecoilValue(clientIsMobile)

  return (
    <>
      <ProfileLayout pageName="ЗАХИАЛГА">
        {' '}
        {/*주문내역조회*/}
        <Tabs isFitted variant={'enclosed'} borderWidth={0}>
          {isMobile ? (
            <TabList borderWidth={0}>
              <Tab
                borderBottom={'1px solid gray'}
                _selected={{
                  ...selectedStyle,
                  borderRight: '1px solid gray',
                  borderLeft: '1px solid gray',
                }}
                fontSize={'14px'}
              >
                ЗАХИАЛГЫН ЖАГСААЛТ
              </Tab>{' '}
              {/*주문목록*/}
              <Tab
                borderBottom={'1px solid gray'}
                _selected={{
                  ...selectedStyle,
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                }}
                fontSize={'14px'}
              >
                ЦУЦЛАГДСАН ЗАХИАЛГА
              </Tab>{' '}
              {/*취소/환불/반품*/}
            </TabList>
          ) : (
            <TabList borderWidth={0}>
              <Tab
                borderBottom={'1px solid gray'}
                borderRadius={2}
                maxW={250}
                _selected={{
                  ...selectedStyle,
                  borderRight: '1px solid gray',
                  borderLeft: '1px solid gray',
                }}
              >
                ЗАХИАЛГЫН ЖАГСААЛТ
              </Tab>{' '}
              {/*주문목록*/}
              <Tab
                borderRadius={0}
                borderBottom={'1px solid gray'}
                maxW={250}
                _selected={{
                  ...selectedStyle,
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                }}
              >
                ЦУЦЛАГДСАН ЗАХИАЛГА
              </Tab>{' '}
              {/*취소/환불/반품*/}
              <Tab
                isDisabled
                borderBottom={'1px solid black'}
                style={{ backgroundColor: 'white' }}
                left={0}
                position={'relative'}
              ></Tab>
            </TabList>
          )}

          <TabPanels>
            <OrderListPanel panelType={'orderList'} />
            <OrderListPanel panelType={'cancelList'} />
          </TabPanels>
        </Tabs>
      </ProfileLayout>
    </>
  )
}
