import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Stack,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { AuthLayout } from './AuthLayout'
import { SigninHeader } from './component/SigninHeader'
import { CustomLabel } from 'views/component/CustomLabel'
import { CheckIcon } from '@chakra-ui/icons'
import { log } from 'console'
import { SignInDto } from 'domain/signIn'
import hashingString from 'common/util/hashStringUtils'
import { customAlert, isEmpty } from 'common/util/string-utils'
import { useSignInQuery } from 'common/api/domains/auth/authQuery'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export function SignIn() {
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useRecoilState(clientIsMobile)
  const customCss = {
    'td-title': {
      w: isMobile ? '40%' : '30%',
      px: isMobile ? 0 : '',
      textAlign: 'center' as const,
      fontSize: isMobile ? '13px' : '',
    },
    'td-data': {
      w: isMobile ? '60%' : '70%',
      px: isMobile ? 0 : '',
      fontWeight: 700,
      textAlign: 'center' as const,
      fontSize: isMobile ? '13px' : '',
    },
  }
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  })
  const { mutate, isLoading, isError, isSuccess, error } = useSignInQuery()

  const initStatus = () => {
    setEmailStatus(true)
    setPasswordStatus(true)
    setPhoneNumberStatus(true)
    setNameStatus(true)
  }
  const initPhoneNumber = () => {
    setFirstValue('')
    setSecondValue('')
    setThirdValue('')
  }

  const submitForm = () => {
    initStatus()

    let signInDto: SignInDto = {
      name: nameValue,
      email: emailValue,
      phone: firstValue + '-' + secondValue + '-' + thirdValue,
      password: hashingString(passwordValue),
    }

    mutate(signInDto, {
      onSuccess: (data) => {
        if (!isEmpty(data.code)) {
          if (data.code == 3) {
            initPhoneNumber()
            setEmailValue('')
            setEmailStatus(false)
            setPhoneNumberStatus(false)
            // alert(
            //   'Энэ имэйл болон утасны дугаар аль хэдийн бүртгэгдсэн байна. \n Имэйл болон утасны дугаараа дахин оруулна уу.',
            //   /* 이미 등록되어있는 이메일, 전화번호입니다. \n이메일과 전화번호를 다시 입력하여 주십시오.*/
            // )
            customAlert(
              'Энэ имэйл болон утасны дугаар аль хэдийн бүртгэгдсэн байна. \n Имэйл болон утасны дугаараа дахин оруулна уу.',
            )
          } else if (data.code == 2) {
            setPhoneNumberStatus(false)
            // alert(
            //   'Энэ утасны дугаар аль хэдийн бүртгэгдсэн байна. \n Утасны дугаараа дахин оруулна уу.',
            //   /* 이미 등록되어있는 전화번호입니다. \n전화번호를 다시 입력하여 주십시오.*/
            // )
            customAlert(
              'Энэ утасны дугаар аль хэдийн бүртгэгдсэн байна. \n Утасны дугаараа дахин оруулна уу.',
            )
            initPhoneNumber()
          } else if (data.code == 1) {
            setEmailValue('')
            setEmailStatus(false)
            customAlert(
              'Энэ имэйл аль хэдийн бүртгэгдсэн байна. \nИмэйлээ дахин оруулна уу.',
            )
            // alert(
            //   'Энэ имэйл аль хэдийн бүртгэгдсэн байна. \nИмэйлээ дахин оруулна уу.',
            //   /*이미 등록되어있는 이메일입니다. \n이메일을 다시 입력하여 주십시오.*/
            // )
          }
        } else {
          setPageNum(2)
        }
      },
      onError: (error) => {},
      onSettled: (data, error) => {
        setFormData(data)
      },
    })
  }

  const [checkedItems, setCheckedItems] = useState([false, false])
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [termofusePolicy, setTermofusePolicy] = useState('')
  const allChecked = checkedItems.every(Boolean)

  // 전화번호
  const [firstValue, setFirstValue] = useState('')
  const [secondValue, setSecondValue] = useState('')
  const [thirdValue, setThirdValue] = useState('')
  const [phoneNumberStatus, setPhoneNumberStatus] = useState(true)

  // 이메일
  const [emailValue, setEmailValue] = useState('')
  const [emailStatus, setEmailStatus] = useState(true)
  // 비밀번호
  const [passwordValue, setPasswordValue] = useState('')
  const [passwordConfirmValue, setPasswordConfirmValue] = useState('')
  const [passwordStatus, setPasswordStatus] = useState(true)

  // 이름
  const [nameValue, setNameValue] = useState('')
  const [nameStatus, setNameStatus] = useState(true)

  const onChangeFormValue = (e: ChangeEvent<HTMLInputElement>) => {
    let eventValue = e.target.value as string
    if (e.target.id === 'email') {
      setEmailValue(eventValue)
    } else if (e.target.id === 'password') {
      setPasswordValue(eventValue)
    } else if (e.target.id === 'password-confirm') {
      setPasswordConfirmValue(eventValue)
    } else if (e.target.id === 'name') {
      setNameValue(eventValue)
    } else if (e.target.id === 'firstValue') {
      setFirstValue(eventValue)
    } else if (e.target.id === 'secondValue') {
      setSecondValue(eventValue)
    } else if (e.target.id === 'thirdValue') {
      setThirdValue(eventValue)
    }
  }
  const confirmForm = () => {
    initStatus()

    if (!isValidEmail(emailValue)) {
      setEmailValue('')
      setEmailStatus(false)
      return
    }
    if (!isValidPassword()) {
      setPasswordStatus(false)
      return
    }
    if (!isValidName()) {
      setNameStatus(false)
      return
    }
    if (!isValidPhoneNumber()) {
      setPhoneNumberStatus(false)
      return
    }
    submitForm()
    // setPageNum(2)
  }

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const isValidName = () => {
    return nameValue.trim() !== ''
  }

  function isValidPassword(): boolean {
    if (passwordValue !== passwordConfirmValue) {
      return false
    }
    if (passwordValue.length < 8 || passwordValue.length > 12) {
      return false
    }

    // const uppercaseRegex = /[A-Z]/
    // const lowercaseRegex = /[a-z]/
    // const numberRegex = /[0-9]/
    // const specialCharRegex = /[^A-Za-z0-9]/

    // let typesCount = 0
    // if (uppercaseRegex.test(passwordValue)) typesCount++
    // if (lowercaseRegex.test(passwordValue)) typesCount++
    // if (numberRegex.test(passwordValue)) typesCount++
    // if (specialCharRegex.test(passwordValue)) typesCount++

    return true
  }

  const isValidPhoneNumber = () => {
    return (
      firstValue.trim() !== '' &&
      secondValue.trim() !== '' &&
      thirdValue.trim() !== ''
    )
  }

  useEffect(() => {
    fetch('/privacyPolicy.txt')
      .then((response) => response.text())
      .then((data) => setPrivacyPolicy(data))
      .catch((error) => console.error('Error fetching the text file:', error))
    fetch('/termofusePolicy.txt')
      .then((response) => response.text())
      .then((data) => setTermofusePolicy(data))
      .catch((error) => console.error('Error fetching the text file:', error))
  }, [])

  const [pageNum, setPageNum] = useState(0)
  const goToSignInForm = () => {
    if (checkedItems.every((item) => item === true)) {
      setPageNum(1)
    } else {
      alert(
        'Гишүүнээр бүртгүүлэх болзол, нөхцлийг зөвшөөрнө үү.' /*회원가입을 위해 약관에 동의하여 주십시오.*/,
      )
    }
  }
  const goToMain = () => {
    navigate('/')
  }

  return (
    <AuthLayout
      pageName="БҮРТГҮҮЛЭХ"
      /*회원가입*/ pageHeader={<SigninHeader />}
    >
      {pageNum === 0 ? (
        <Box p={1} pt={5}>
          <Checkbox
            isChecked={allChecked}
            borderRadius="md"
            onChange={(e) =>
              setCheckedItems([e.target.checked, e.target.checked])
            }
          >
            Бүгдийг зөвшөөрч байна. {/*전체 동의*/}
          </Checkbox>
          {/*<Box ml={6} fontSize={isMobile ? '12px' : '14px'}>
            <Text>
              {isMobile ? (
                <>
                  이용약관 및 개인정보수집 및 이용, <br />
                  쇼핑정보 수진(선택)에 모두 동의합니다.
                </>
              ) : (
                '이용약관 및 개인정보수집 및 이용, 쇼핑정보 수진(선택)에 모두 동의합니다.'
              )}
            </Text>
            <Text>이용약관 및 개인정보 수집 및 이용에 모두 동의합니다.</Text>
          </Box> */}
          <Divider borderColor="black" borderWidth="1px" my={5} />
          <Stack mt={1} spacing={1}>
            <Checkbox
              py={2}
              isChecked={checkedItems[0]}
              onChange={(e) =>
                setCheckedItems([e.target.checked, checkedItems[1]])
              }
            >
              Үйлчилгээний нөхцөл (ЗАЙЛШГҮЙ ) {/*이용약관 동의(필수)*/}
            </Checkbox>
            <Textarea
              resize={'none'}
              h={100}
              fontSize={10}
              size="sm"
              isReadOnly
              value={termofusePolicy.replace(/\n/g, '\n')}
            />
            <Checkbox
              py={2}
              pt={5}
              isChecked={checkedItems[1]}
              onChange={(e) =>
                setCheckedItems([checkedItems[0], e.target.checked])
              }
            >
              Аюулгүй ажиллагаа (ЗАЙЛШГҮЙ ){' '}
              {/*개인정보 수집 및 이용 동의(필수)*/}
            </Checkbox>
            <Textarea
              resize={'none'}
              size="sm"
              fontSize={10}
              h={100}
              isReadOnly
              value={privacyPolicy.replace(/\n/g, '\n')}
            />
          </Stack>
          <Divider borderColor="gray.400" my={5} />
          <Flex>
            <Button
              w="50%"
              colorScheme="blue"
              variant="outline"
              id="findId"
              mr={3}
              onClick={goToMain}
            >
              ЦУЦЛАХ
            </Button>
            <Button
              w="50%"
              colorScheme="blue"
              id="findPassword"
              onClick={goToSignInForm}
            >
              ОЙЛГОЛОО
            </Button>
          </Flex>
        </Box>
      ) : pageNum === 1 ? (
        <Box p={5}>
          <Box textAlign={'center'}>
            <Text as="b" fontSize="xl">
              ХУВИЙН МЭДЭЭЛЭЛ {/*기본정보*/}
            </Text>
          </Box>
          <Divider borderColor="gray.400" my={1} />
          <CustomLabel labelValue={'ИМЭЙЛ*'} /> {/*이메일(아이디)*/}
          <Input
            id="email"
            type="email"
            mb={2}
            onChange={onChangeFormValue}
            value={emailValue}
          />
          {!emailStatus && (
            <Text color="red.500" fontSize="xs" mb={2}>
              Нэвтрэх ашиглах имэйл хаягаа оруулна уу.{' '}
              {/*아이디를 입력해주세요*/}
            </Text>
          )}
          <CustomLabel labelValue={'НУУЦ ҮГ*'} /> {/*비밀번호*/}
          <Input id="password" type="password" onChange={onChangeFormValue} />
          <Text fontSize={isMobile ? '10px' : 'xs'} color={'gray'} mb={2}>
            (үсэг/тоо/тусгай тэмдэгтийн хослолыг ашиглан 8-12 тоо дотор){' '}
            {/*영문/숫자/특수문자를 조합해서 8자~12자 이내*/}
          </Text>
          <CustomLabel labelValue={'НУУЦ ҮГ ДАХИН БИЧИХ*'} />{' '}
          {/*비밀번호 확인*/}
          <Input
            id="password-confirm"
            type="password"
            mb={2}
            onChange={onChangeFormValue}
          />
          {!passwordStatus && (
            <Text color="red.500" fontSize="xs" mb={2}>
              НУУЦ ҮГ ТААРАХГҮЙ БАЙНА. {/*비밀번호가 일치하지 않습니다.*/}
            </Text>
          )}
          <CustomLabel labelValue={'НЭР'} /> {/*이름*/}
          <Input id="name" mb={2} onChange={onChangeFormValue} />
          {!nameStatus && (
            <Text color="red.500" fontSize="xs" mb={2}>
              НЭРЭЭ ОРУУЛНА УУ. {/*이름을 입력해주세요*/}
            </Text>
          )}
          <CustomLabel labelValue={'УТАС'} /> {/*휴대전화*/}
          <HStack spacing={2}>
            <Input
              id="firstValue"
              maxLength={3}
              type="tel"
              onChange={onChangeFormValue}
              value={firstValue}
            />
            <Text>-</Text>
            <Input
              id="secondValue"
              maxLength={4}
              type="tel"
              onChange={onChangeFormValue}
              value={secondValue}
            />
            <Text>-</Text>
            <Input
              id="thirdValue"
              onChange={onChangeFormValue}
              maxLength={4}
              type="tel"
              value={thirdValue}
            />
          </HStack>
          {!phoneNumberStatus && (
            <Text color="red.500" fontSize="xs" mb={2}>
              УТАСНЫ ДУГААРАА ОРУУЛНА УУ. {/*휴대폰 전화를 입력하세요.*/}
            </Text>
          )}
          <Divider borderColor="gray.400" my={4} mt={5} mb={5} />
          <Button
            w="100%"
            colorScheme="blue"
            id="findPassword"
            onClick={confirmForm}
          >
            БҮРТГҮҮЛЭХ {/*가입하기*/}
          </Button>
        </Box>
      ) : (
        <Box p={5}>
          <Stack alignItems={'center'}>
            <CheckIcon
              borderRadius={50}
              boxSize={10}
              bg={'blue.600'}
              color={'white'}
              p={1}
            ></CheckIcon>
            <Text as="b" fontSize={isMobile ? 'md' : '2xl'}>
              БҮРТГЭЛЭЭ ДУУСГАСАНД БАЯР ХҮРГЭЕ.
            </Text>
            <Text fontSize={isMobile ? 'xs' : ''}>
              {/*빌렉에서 다양한 혜택을 경험해 보세요!*/}
            </Text>
            <Divider borderColor="gray.400" my={4} />
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td {...customCss['td-title']} textAlign={'left'}>
                    <Text fontSize={isMobile ? 'xs' : ''}>
                      ИМЭЙЛ {/*이메일(아이디)*/}
                    </Text>
                  </Td>
                  <Td {...customCss['td-data']} textAlign={'left'}>
                    {formData.email}
                  </Td>
                </Tr>
                <Tr>
                  <Td {...customCss['td-title']} textAlign={'left'}>
                    <Text fontSize={isMobile ? 'xs' : ''}>НЭР {/*이름*/}</Text>
                  </Td>
                  <Td {...customCss['td-data']} textAlign={'left'}>
                    {formData.name}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box py={2}></Box>
            <Link
              to="/goods/categories/1/products"
              style={{
                width: '100%',
              }}
              // css={{
              //   textDecoration: 'none',
              //   '&:hover': {
              //     textDecoration: 'none',
              //   },
              // }}
              // w={'100%'}
            >
              <Button colorScheme="blue" w={'100%'}>
                HOME
              </Button>
            </Link>
            <Box pt={1}></Box>
            <Link
              to="/auth/login"
              // css={{
              //   textDecoration: 'none',
              //   '&:hover': {
              //     textDecoration: 'none',
              //   },
              // }}
              style={{
                width: '100%',
              }}
            >
              <Button colorScheme="blue" variant={'outline'} w={'100%'}>
                НЭВТРЭХ {/*로그인*/}
              </Button>
            </Link>
          </Stack>
        </Box>
      )}
    </AuthLayout>
  )
}
