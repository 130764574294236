import React from 'react'
import { BasketDesktop } from './desktop'
import { BasketMobile } from './mobile'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export const Basket = () => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <BasketMobile />
  return <BasketDesktop />
}
