import {
  useNumberInput,
  HStack,
  Button,
  Input,
  Image,
  Text,
} from '@chakra-ui/react'
import React, { ReactNode, useEffect } from 'react'
type labelValue = {
  labelValue: string
}
export const CustomLabel = ({ labelValue }: labelValue) => {
  return (
    <Text as="abbr" fontSize={'xs'}>
      {labelValue}
    </Text>
  )
}
