import { createAxiosInstance } from 'common/config/axios.config'

const commonAxiosInstance = createAxiosInstance(false)

export function fetchOwnerAccount(): Promise<string> {
  return commonAxiosInstance
    .get<string>('/common/owner/account')
    .then((response) => {
      return response.data || ''
    })
}
