import { Box, Button, Checkbox, Divider, Flex, Text } from '@chakra-ui/react'
import {
  useDeleteCarts,
  useUpdateCart,
} from 'common/api/domains/purchase/purchaseMutation'
import {
  useGetCart,
  useGetCost,
} from 'common/api/domains/purchase/purchaseQuery'
import { UpdateCartRequestDTO } from 'domain/purchase/request.dto'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { cartsState, selectedCartIdsState } from 'state/purchase/atoms'
import { CountInput } from 'views/component/CountInput'
import { MoneyText } from 'views/component/MoneyText'

type BasketItemProps = {
  cartId: number
  refetchCartIds: () => void
}

export const BasketItem = ({ cartId, refetchCartIds }: BasketItemProps) => {
  const [selectedCartIds, setSelectedCartIds] =
    useRecoilState(selectedCartIdsState)
  const { data: cart, refetch: refetchCart, isFetched } = useGetCart(cartId)
  const setCarts = useSetRecoilState(cartsState)
  const { mutate: updateCart } = useUpdateCart()
  const { mutate: deleteCarts } = useDeleteCarts()

  const onChangeCheckbox = () => {
    setSelectedCartIds((prev) => {
      if (prev.includes(cartId)) {
        return [...prev].filter((_cartId) => _cartId !== cartId)
      }
      return [...prev, cartId]
    })
  }

  const onChangeCount = async (value: string) => {
    if (!cart) return
    const updateCartRequestDto: UpdateCartRequestDTO = {
      cartId: cart.id,
      count: +value,
    }
    await updateCart(updateCartRequestDto, {
      onSuccess: () => {
        refetchCart()
      },
    })
  }

  const onClickDelete = async () => {
    await deleteCarts([cartId], {
      onSuccess: () => {
        refetchCartIds()
        setCarts((prev) => {
          const newState = { ...prev }
          delete newState[cartId]
          return newState
        })
      },
    })
  }

  useEffect(() => {
    if (!cart) return
    setCarts((prev) => ({
      ...prev,
      [cartId]: cart,
    }))
  }, [cart])

  useEffect(() => {
    if (!isFetched) return
    setSelectedCartIds((prev) => {
      if (prev.includes(cartId)) {
        return [...prev]
      }
      return [...prev, cartId]
    })
  }, [isFetched])

  if (!cart) return null
  return (
    <Flex flexDirection={'column'} pl={5} pr={5}>
      <Flex mt={4} alignItems={'start'}>
        <Checkbox
          size={'lg'}
          m={1}
          isChecked={selectedCartIds.includes(cart.id!)}
          onChange={onChangeCheckbox}
        />
        <Box ml={4} flex={1}>
          <Flex justifyContent={'space-between'} alignItems={'center'} mb={4}>
            <Text>{cart.product?.name || ''}</Text>
            <Button variant={'link'} onClick={onClickDelete}>
              X
            </Button>
          </Flex>
          <Flex mb={2} justifyContent={'space-between'} alignItems={'center'}>
            <MoneyText amount={cart.product?.price || 0} unit={'₩'} />
            <CountInput defaultValue={cart.count} onChange={onChangeCount} />
          </Flex>
        </Box>
      </Flex>
      <Divider />
    </Flex>
  )
}
