import { useMutation } from 'react-query'
import {
  createCarts,
  createOrder,
  deleteCarts,
  updateCart,
} from '../../fetcher/purchase/mutations'
import { CartModel, OrderModel } from 'domain/purchase/models'
import {
  CreateCartRequestDTO,
  CreateOrderProductRequestDTO,
  CreateOrderRequestDTO,
  UpdateCartRequestDTO,
} from 'domain/purchase/request.dto'
import { DeleteResultResponse } from 'common/util/type'

export const useCreateCarts = () =>
  useMutation<boolean, Error, CreateCartRequestDTO[]>(createCarts)

export const useUpdateCart = () =>
  useMutation<CartModel, Error, UpdateCartRequestDTO>({
    mutationFn: (updateCartRequestDto) => updateCart(updateCartRequestDto),
  })

export const useDeleteCarts = () =>
  useMutation<DeleteResultResponse, Error, number[]>({
    mutationFn: (cartIds: number[]) => deleteCarts(cartIds),
  })

export const useCreateOrder = () =>
  useMutation<
    OrderModel,
    Error,
    {
      createOrderRequestDto: CreateOrderRequestDTO
      createOrderProductRequestDtos: CreateOrderProductRequestDTO[]
    }
  >({
    mutationFn: ({ createOrderRequestDto, createOrderProductRequestDtos }) =>
      createOrder(createOrderRequestDto, createOrderProductRequestDtos),
  })
