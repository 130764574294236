import { Box, Heading, Text } from '@chakra-ui/react'
import { useState } from 'react'

export function ForgotPasswordHeader() {
  const [activeButton, setActiveButton] = useState<string>('findId')

  return (
    <>
      <Heading mb={3} fontSize={25} textAlign={'center'}>
        {activeButton === 'findId' ? 'ХАЯГ/НУУЦ ҮГ ХАЙХ' : '비밀번호 찾기'}
      </Heading>{' '}
      {/*아이디/비밀번호찾기 헤더*/}
    </>
  )
}
