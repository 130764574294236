import {
  fetchAllProductList,
  fetchCategories,
  fetchCategory,
  fetchProduct,
  fetchProductList,
  getCommonCodeFetch,
} from 'common/api/fetcher/goods/queries'
import { useQuery } from 'react-query'

export default function goodsQuery() {
  // todo
}

export const useProductQuery = (productId: number | string) =>
  useQuery(['goods', 'products', productId], () => fetchProduct(productId))

export const useCategoriesQuery = () =>
  useQuery(['goods', 'categories'], () => fetchCategories())

export const useCategoryQuery = (categoryId: number | string) =>
  useQuery(['goods', 'categories', categoryId], () => fetchCategory(categoryId))

export const useAllProductListQuery = () =>
  useQuery(['products'], () => fetchAllProductList())

export const useProductListQuery = (categoryId: number | string) =>
  useQuery(['goods', 'categories', categoryId, 'products'], () =>
    fetchProductList(categoryId),
  )

export const getCommonCodeQuery = () =>
  useQuery(['goods', 'common'], () => getCommonCodeFetch())
