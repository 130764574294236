import { createAxiosInstance } from 'common/config/axios.config'

const axiosInstance = createAxiosInstance(true)

export function extractAddressFromImage(imageId: number): Promise<string> {
  return axiosInstance
    .post<string>(`/purchase/parse/address`, { imageId })
    .then((response) => {
      return response.data || ''
    })
}
