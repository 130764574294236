import {
  Tab,
  TabList,
  Tabs,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  Button,
  Checkbox,
  Container,
  Flex,
  Divider,
  HStack,
  Spacer,
} from '@chakra-ui/react'
import { ProfileLayout } from './ProfileLayout'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  useChangeDefaultAddressQuery,
  useMyDeliveryAddressQuery,
} from 'common/api/domains/profile/profileQuery'
import { UserAddressProps } from 'domain/profile'
import { useNavigate } from 'react-router-dom'
import { log } from 'console'
import { useRecoilState, useRecoilValue } from 'recoil'
import { checkAddressState } from 'state/profile/atoms'
import { isEmpty } from 'common/util/string-utils'
import { clientIsMobile } from 'state/common/atoms'
import { color } from 'framer-motion'
interface CheckedItemsState {
  [key: number]: boolean
}

export function DeliveryAddressManagement() {
  const isMobile = useRecoilValue(clientIsMobile)

  const navigate = useNavigate()
  const [allChecked, setAllChecked] = useState(false)
  const { data: UserAddressProps } = useMyDeliveryAddressQuery()
  const [checkedAddress, setCheckedAddress] = useRecoilState(checkAddressState)
  const [checkedItems, setCheckedItems] = useState<CheckedItemsState>({})
  const { mutate, isLoading, isError, isSuccess, error } =
    useChangeDefaultAddressQuery()
  useEffect(() => {
    let ids: number[] = []
    Object.keys(checkedItems).forEach((id) => {
      if (checkedItems[parseInt(id)] === true) {
        ids.push(parseInt(id))
      }
    })
    setCheckedAddress(ids)
  }, [checkedItems])
  useEffect(() => {
    if (
      UserAddressProps &&
      UserAddressProps?.length != 0 &&
      Array.isArray(UserAddressProps)
    ) {
      let tmp = UserAddressProps?.map((address) => {
        return { [address.id ? address.id : 0]: false }
      })
      let initArray = tmp ? Object.assign({}, ...tmp) : {}

      setCheckedItems(initArray ? initArray : {})
    }
  }, [UserAddressProps])
  const handleAllChecked = (e: ChangeEvent<HTMLInputElement>) => {
    if (UserAddressProps && UserAddressProps?.length != 0) {
      const checked = e.target.checked
      setAllChecked(checked)
      const newCheckedItems: CheckedItemsState = {}
      Object.keys(checkedItems).forEach((key) => {
        newCheckedItems[Number(key)] = checked
      })
      setCheckedItems(newCheckedItems)
    }
  }

  const changeDefaultAddress = (key: number) => {
    mutate(key, {
      onSuccess: () => {
        navigate(0)
        localStorage.setItem('default-address', key.toString())
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    })
  }

  const handleIndividualChecked =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      if (UserAddressProps && UserAddressProps?.length != 0) {
        const updatedCheckedItems = {
          ...checkedItems,
          [index]: event.target.checked,
        }

        setCheckedItems(updatedCheckedItems)
        setAllChecked(Object.values(updatedCheckedItems).every((item) => item))
      }
    }
  const modifyAddress = (address: UserAddressProps) => {
    navigate('/management/profile/delivery/modify', { state: address })
  }
  return (
    <>
      <ProfileLayout pageName="ХҮРГЭЛТИЙН ХАЯГ">
        {' '}
        {/*배송지 관리*/}
        <Tabs isFitted variant={'enclosed'} borderWidth={0}>
          <TableContainer pb={3}>
            <Table size="sm">
              {!isMobile ? (
                <Thead
                  textAlign={'center'}
                  borderTop={'1px solid black'}
                  height={10}
                  backgroundColor={'#F4F4F4'}
                >
                  <Tr>
                    <Th>
                      <Checkbox
                        isChecked={allChecked}
                        onChange={handleAllChecked}
                      />{' '}
                    </Th>
                    <Th>
                      <Text as="b" color={'black'}>
                        БАЙНГЫН ХАЯГ
                      </Text>{' '}
                      {/*기본배송지*/}
                    </Th>
                    <Th>
                      <Text as="b" color={'black'}>
                        ХҮЛЭЭН АВАГЧ
                      </Text>{' '}
                      {/*수령인*/}
                    </Th>
                    <Th>
                      <Text as="b" color={'black'}>
                        УТАС
                      </Text>{' '}
                      {/*휴대전화*/}
                    </Th>
                    <Th>
                      <Text as="b" color={'black'}>
                        ХАЯГ
                      </Text>{' '}
                      {/*주소*/}
                    </Th>
                    <Th textAlign={'center'}></Th>
                  </Tr>
                </Thead>
              ) : (
                <></>
              )}
              <Tbody>
                {UserAddressProps &&
                UserAddressProps?.length !== 0 &&
                Array.isArray(UserAddressProps)
                  ? UserAddressProps?.map((address) => (
                      <Tr key={address.id}>
                        {!isMobile ? (
                          <>
                            <Td>
                              <Checkbox
                                isChecked={checkedItems[Number(address.id)]}
                                onChange={handleIndividualChecked(
                                  Number(address.id),
                                )}
                              />
                            </Td>
                            <Td textAlign={'center'}>
                              {address &&
                              address.id &&
                              address.id.toString() ==
                                localStorage.getItem('default-address') ? (
                                <Text>СОНГОГДСОН {/*지정됨*/}</Text>
                              ) : (
                                <Button
                                  height={'30px'}
                                  textAlign={'center'}
                                  borderRadius={4}
                                  bgColor={'#3182ce'}
                                  color={'#ffffff'}
                                  variant="outline"
                                  _hover={{
                                    background: 'white',
                                    color: '#3182ce',
                                  }}
                                  onClick={() =>
                                    changeDefaultAddress(Number(address.id))
                                  }
                                >
                                  <Text fontSize="md">СОНГОХ</Text> {/*변경*/}
                                </Button>
                              )}
                            </Td>
                            <Td>
                              <Text display={'flex'}>{address.name}</Text>
                            </Td>
                            <Td>
                              <Text display={'flex'}>{address.phone}</Text>
                            </Td>
                            <Td>
                              <Text>
                                ({address.postcode}) {address.address1}{' '}
                                {address.address2}
                              </Text>
                            </Td>
                            <Td textAlign={'center'}>
                              <Button
                                backgroundColor="white"
                                color={'#3182ce'}
                                height={'30px'}
                                mt={0}
                                w={'100%'}
                                borderRadius={4}
                                border={'1px solid #3182ce'}
                                onClick={() => modifyAddress(address)}
                              >
                                ЗАСАХ
                              </Button>{' '}
                              {/*수정*/}
                            </Td>
                          </>
                        ) : (
                          <>
                            <Container pb={0} mt={2}>
                              <Container
                                borderBottom={'0.5px solid gray'}
                                pb={3}
                              >
                                <HStack
                                  width="100%"
                                  pt={3}
                                  pr={3}
                                  alignItems="center"
                                >
                                  {/* <Flex> */}
                                  <Checkbox
                                    isChecked={checkedItems[Number(address.id)]}
                                    onChange={handleIndividualChecked(
                                      Number(address.id),
                                    )}
                                    pt={isMobile ? 0 : 3}
                                    pr={isMobile ? 0 : 3}
                                  />
                                  {address &&
                                  address.id &&
                                  address.id.toString() ==
                                    localStorage.getItem('default-address') ? (
                                    <Text
                                      display={'inline-block'}
                                      height={30}
                                      my={3}
                                      ml={2}
                                      mr={2}
                                    >
                                      СОНГОГДСОН
                                    </Text>
                                  ) : (
                                    <Button
                                      height={'30px'}
                                      bgColor={'#3182ce'}
                                      border={'1px solid #3182ce'}
                                      color={'#ffffff'}
                                      w={'50%'}
                                      onClick={() =>
                                        changeDefaultAddress(Number(address.id))
                                      }
                                    >
                                      <Text fontSize="xs">СОНГОХ</Text>
                                    </Button>
                                  )}
                                  <Spacer />
                                  {/* </Flex> */}
                                  <Button
                                    backgroundColor="white"
                                    color={'#3182ce'}
                                    height={'30px'}
                                    onClick={() => modifyAddress(address)}
                                    w={'50%'}
                                    borderRadius={4}
                                    border={'1px solid #3182ce'}
                                    my={3}
                                    ml={2}
                                    mr={2}
                                  >
                                    ЗАСАХ
                                  </Button>{' '}
                                  {/*수정*/}
                                </HStack>
                                <HStack>
                                  <Text display={'flex'} mr={5}>
                                    {address.name}
                                  </Text>
                                  <Text display={'flex'}>{address.phone}</Text>
                                </HStack>
                                <Text
                                  display={'inline-block'}
                                  whiteSpace="normal"
                                >
                                  ({address.postcode}) {address.address1}{' '}
                                </Text>
                                <Text>{address.address2}</Text>
                              </Container>
                            </Container>
                          </>
                        )}
                      </Tr>
                    ))
                  : 'БҮРТГЭГДСЭН ХАЯГ БАЙХГҮЙ.'}{' '}
                {/*주소없음 안내문구*/}
              </Tbody>
            </Table>
          </TableContainer>
        </Tabs>
      </ProfileLayout>
    </>
  )
}
