import { useMutation, useQuery } from 'react-query'
import { FindDto, LoginDto, newPasswordDto, SignInDto } from 'domain/signIn'
import {
  findIdFetcher,
  findPasswordFetcher,
  loginFetcher,
  logoutFetcher,
  newPasswordFetcher,
  signInFetcher,
  signOutFetcher,
} from 'common/api/fetcher/auth'

export const useSignInQuery = () => {
  return useMutation((formData: SignInDto) => signInFetcher(formData))
}

export const useLoginQuery = () => {
  return useMutation((formData: LoginDto) => loginFetcher(formData))
}
export const useSignOutQuery = () => {
  return useMutation(() => signOutFetcher())
}
export const useLogoutQuery = () => {
  return useMutation((data: boolean) => logoutFetcher(data))
}

export const useFindPasswordQuery = () => {
  return useMutation((data: FindDto) => findPasswordFetcher(data))
}

export const useFindIdQuery = () => {
  return useMutation((data: FindDto) => findIdFetcher(data))
}
export const useNewPasswordQuery = () => {
  return useMutation((data: newPasswordDto) => newPasswordFetcher(data))
}
