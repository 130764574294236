import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { ProductItem } from './ProductItem'
import { Link } from 'react-router-dom'
import { useAllProductListQuery } from 'common/api/domains/goods/goodsQuery'

export const CategoryListDesktop = () => {
  const { data: products } = useAllProductListQuery()

  return (
    <Box w={'1200px'}>
      <Text fontSize={20} fontWeight={'bold'} my={8} ml={5}>
        БҮТЭЭГДЭХҮҮН
      </Text>{' '}
      {/*상품*/}
      <Grid
        w={'1200px'}
        templateColumns={'repeat(auto-fill, 272px)'}
        gap={'26px'}
        justifyContent={'center'}
      >
        {products &&
          products
            .filter((product) => product.is_main_display)
            .sort((a, b) => a.main_seq - b.main_seq)
            .map((product, index) => (
              <GridItem key={index}>
                <Link to={`/goods/products/${product.id}`}>
                  <ProductItem product={product} />
                </Link>
              </GridItem>
            ))}
      </Grid>
    </Box>
  )
}
