import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'
const queryClient = new QueryClient()

document.title = process.env.REACT_APP_API_MALL_TITLE || 'React App'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </RecoilRoot>,
)
