import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { ProductInformationTabs } from './ProductInformationTabs'
import { BreadcrumbNavigation } from 'views/component/BreadcrumbNavigation'
import { useProductQuery } from 'common/api/domains/goods/goodsQuery'
import { ProductTopInfo } from './ProductTopInfo'

type ProductDetailProps = {
  productId: number
}

export const ProductDetailDesktop = ({ productId }: ProductDetailProps) => {
  const { data: product } = useProductQuery(productId)
  const category = product?.category
  if (!product || !category) return null

  return (
    <Box w={'1200px'}>
      <Flex justifyContent={'end'}>
        <BreadcrumbNavigation
          items={[
            {
              name: 'HOME',
              breadcrumbLinkProps: { href: '/goods/categories' },
            },
            {
              name: category.name,
              breadcrumbLinkProps: {
                href: `/goods/categories/${category.id}/products`,
              },
            },
            {
              name: product.name,
              breadcrumbItemProps: { isCurrentPage: true },
            },
          ]}
        />
      </Flex>
      <Box h={'64px'} />
      <ProductTopInfo product={product} />
      <Box h={'128px'} />
      <ProductInformationTabs product={product} />
    </Box>
  )
}
