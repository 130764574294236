import React from 'react'
import { ProductDetailMobile } from './mobile'
import { ProductDetailDesktop } from './desktop'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

type ProductDetailProps = {
  productId: number
}

export const ProductDetail = ({ productId }: ProductDetailProps) => {
  const isMobile = useRecoilValue(clientIsMobile)

  if (isMobile) return <ProductDetailMobile productId={productId} />
  return <ProductDetailDesktop productId={productId} />
}
