import { Grid, GridItem } from '@chakra-ui/react'
import { useMyDeliveryAddressQuery } from 'common/api/domains/profile/profileQuery'
import { AddressForm, useAddress } from 'common/hooks/useAddress'
import React, { useEffect } from 'react'

type BasicAddressProps = {
  handleAddress: (address: AddressForm) => void
  isActive: boolean
}

export const BasicAddress = ({
  handleAddress,
  isActive,
}: BasicAddressProps) => {
  const { data: addresses, isFetched } = useMyDeliveryAddressQuery()
  const basicAddress = useAddress()

  useEffect(() => {
    if (isFetched && addresses && addresses.length > 0) {
      basicAddress.setAddress(addresses[0])
    }
  }, [isFetched])

  useEffect(() => {
    if (!isActive) return
    handleAddress(basicAddress.address)
  }, [
    isActive,
    basicAddress.address.name,
    basicAddress.address.postcode,
    basicAddress.address.address1,
    basicAddress.address.address2,
    basicAddress.address.phone,
  ])

  return (
    <Grid templateColumns={'128px 1fr'} rowGap={4} mb={8}>
      <GridItem display={'flex'} alignItems={'center'} color={'#505050'}>
        ХҮЛЭЭН АВАГЧ
      </GridItem>{' '}
      {/*수령인*/}
      <GridItem>{basicAddress.address.name}</GridItem>
      <GridItem display={'flex'} alignItems={'center'} color={'#505050'}>
        ХАЯГ
      </GridItem>{' '}
      {/*주소*/}
      <GridItem>{`[${basicAddress.address.postcode}] ${basicAddress.address.address1} ${basicAddress.address.address2}`}</GridItem>
      <GridItem display={'flex'} alignItems={'center'} color={'#505050'}>
        УТАС
      </GridItem>{' '}
      {/*휴대전화*/}
      <GridItem>{basicAddress.address.phone}</GridItem>
    </Grid>
  )
}
