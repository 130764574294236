import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { BasketAddon } from './BasketAddon'
import { BasketList } from './BasketList'

export const BasketDesktop = () => {
  return (
    <Box w={'1200px'}>
      <Text m={8} fontSize={'xl'} fontWeight={'bold'} textAlign={'center'}>
        САГС
      </Text>{' '}
      {/*장바구니*/}
      <Flex>
        <BasketList />
        <BasketAddon />
      </Flex>
    </Box>
  )
}
