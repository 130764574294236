import React, { useCallback, useState } from 'react'

type UseAddressProps = {
  name?: string
  postcode?: string
  address1?: string
  address2?: string
  phone?: string
}

export type AddressForm = {
  name: string
  postcode: string
  address1: string
  address2: string
  phone: string
}

export const useAddress = (props?: UseAddressProps) => {
  const [name, setName] = useState(props?.name || '')
  const [postcode, setPostcode] = useState(props?.postcode || '')
  const [address1, setAddress1] = useState(props?.address1 || '')
  const [address2, setAddress2] = useState(props?.address2 || '')
  const [phone, setPhone] = useState(props?.phone || '--')

  const onChangeName = useCallback((value: string) => {
    setName(value)
  }, [])
  const onChangePostcode = useCallback((value: string) => {
    setPostcode(value)
  }, [])
  const onChangeAddress1 = useCallback((value: string) => {
    setAddress1(value)
  }, [])
  const onChangeAddress2 = useCallback((value: string) => {
    setAddress2(value)
  }, [])
  const onChangePhone = useCallback((value: string, part: 0 | 1 | 2) => {
    setPhone((prev) => {
      switch (part) {
        case 0: {
          if (!/^\d{0,4}$/.test(value)) return prev
          return value + '-' + prev.split('-')[1] + '-' + prev.split('-')[2]
        }
        case 1: {
          if (!/^\d{0,4}$/.test(value)) return prev
          return prev.split('-')[0] + '-' + value + '-' + prev.split('-')[2]
        }
        case 2: {
          if (!/^\d{0,4}$/.test(value)) return prev
          return prev.split('-')[0] + '-' + prev.split('-')[1] + '-' + value
        }
        default: {
          return '--'
        }
      }
    })
  }, [])

  const setAddress = useCallback(
    (address: UseAddressProps) => {
      setName(address.name || '')
      setPostcode(address.postcode || '')
      setAddress1(address.address1 || '')
      setAddress2(address.address2 || '')
      setPhone(address.phone || '--')
    },
    [setName, setPostcode, setAddress1, setAddress2, setPhone],
  )

  return {
    onChangeName,
    onChangePostcode,
    onChangeAddress1,
    onChangeAddress2,
    onChangePhone,
    setAddress,
    address: {
      name,
      postcode,
      address1,
      address2,
      phone,
    },
  }
}
