import {
  Flex,
  ButtonGroup,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { AddBasketModal } from './AddBasketModal'
import { useFormikContext } from 'formik'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { useCreateCarts } from 'common/api/domains/purchase/purchaseMutation'
import { CreateCartRequestDTO } from 'domain/purchase/request.dto'
import { OrderProductForm } from 'domain/purchase/forms'
import { ProductModel } from 'domain/goods/model'
import { useSetRecoilState } from 'recoil'
import {
  orderProductFormsState,
  isOrderFromCartState,
} from 'state/purchase/atoms'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'common/util/string-utils'
import { useAuth } from 'common/hooks/useAuth'
import { CustomModal } from 'views/component/CustomModal'

type CostSummaryProps = {
  product: ProductModel
}

export const CostSummary = ({ product }: CostSummaryProps) => {
  const { user } = useAuth()
  const { values } = useFormikContext<CreateCartRequestDTO>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const { goTo: goToOrder } = usePrivateNavigate('/purchase/order')
  const { mutate: createCarts } = useCreateCarts()
  const setOrderProductForms = useSetRecoilState(orderProductFormsState)
  const setIsOrderFromCart = useSetRecoilState(isOrderFromCartState)
  const navigate = useNavigate()

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalSubmit = () => {
    // Logic to handle redirection or submission
    navigate('/auth/login')
    setIsModalOpen(false)
  }

  const onClickAddBasket = async () => {
    if (
      isEmpty(localStorage.getItem('jwt-token')) ||
      isEmpty(localStorage.getItem('refresh-token')) ||
      isEmpty(localStorage.getItem('user')) ||
      isEmpty(localStorage.getItem('phone'))
    ) {
      setModalMessage(
        'НЭВТРЭХ ШААРДЛАГАТАЙ. ТА НЭВТЭРМЭЭР БАЙНА УУ?' /*로그인이 필요한 서비스입니다. 로그인 하시겠습니까?*/,
      )
      setIsModalOpen(true)
    } else {
      await createCarts([{ ...values, userId: user?.id || -1 }])
      onOpen()
    }
  }

  const onClickOrder = () => {
    if (
      isEmpty(localStorage.getItem('jwt-token')) ||
      isEmpty(localStorage.getItem('refresh-token')) ||
      isEmpty(localStorage.getItem('user')) ||
      isEmpty(localStorage.getItem('phone'))
    ) {
      setModalMessage(
        'НЭВТРЭХ ШААРДЛАГАТАЙ. ТА НЭВТЭРМЭЭР БАЙНА УУ?' /*로그인이 필요한 서비스입니다. 로그인 하시겠습니까?*/,
      )
      setIsModalOpen(true)
    } else {
      const orderProductForms: OrderProductForm[] = [
        {
          count: values.count,
          productId: product.id,
          productName: product.name,
          price: product.price,
          unit: product.unit,
        },
      ]
      setOrderProductForms(orderProductForms)
      setIsOrderFromCart(false)
      goToOrder()
    }
  }

  if (!product) return null
  return (
    <>
      <Flex justifyContent={'space-between'} mt={4} mb={4}>
        <Text fontSize={20} fontWeight={'bold'}>
          НИЙТ ДҮН(ШИРХЭГ) {/*총 구매금액(수량)*/}
        </Text>
        <MoneyText
          amount={product.price * values.count}
          unit={'₩'}
          suffix={`(${values.count}Ш)`}
          textProps={{ fontSize: 22, fontWeight: 'bold', color: '#005E9E' }}
        />
      </Flex>
      <ButtonGroup mt={4} display={'flex'} justifyContent={'space-between'}>
        <Button
          size={'lg'}
          w={'344px'}
          colorScheme="blue"
          onClick={values.count === 0 ? () => {} : onClickOrder}
        >
          ХУДАЛДАН АВАХ {/*구매하기*/}
        </Button>
        <Button
          size={'lg'}
          w={'200px'}
          colorScheme="blue"
          variant={'outline'}
          onClick={values.count === 0 ? () => {} : onClickAddBasket}
        >
          САГСАНД НЭМЭХ {/*장바구니 담기*/}
        </Button>
        <AddBasketModal isOpen={isOpen} onClose={onClose} />
      </ButtonGroup>
      <CustomModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        submit={handleModalSubmit}
        message={modalMessage}
        submitButtonMessage="ЗА" /*예*/
        closeButtonMessage="ҮГҮЙ" /*아니오*/
      />
    </>
  )
}
