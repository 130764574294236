import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { AuthLayout } from './AuthLayout'
import { LoginHeader } from './component/LoginHeader'
import { ChangeEvent, useState } from 'react'
import { LoginDto } from 'domain/signIn'
import { Link, useNavigate } from 'react-router-dom'
// import {
//   accessTokenState,
//   isLoginState,
//   refreshTokenState,
//   userNameState,
//   userPhoneState,
// } from 'state/auth/atoms'
import { LoginSuccessVo, useAuth } from 'common/hooks/useAuth'
import { useLoginQuery } from 'common/api/domains/auth/authQuery'
import Swal from 'sweetalert2'
import { customAlert } from 'common/util/string-utils'

export function LoginInfo() {
  const isMobile = useRecoilValue(clientIsMobile)
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const { mutate, isLoading, isError, isSuccess, error } = useLoginQuery()
  const navigate = useNavigate()
  // const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState)
  // const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  // const [isLogin, setIsLogin] = useRecoilState(isLoginState)
  // const [userName, setUserName] = useRecoilState(userNameState)
  // const [phoneNumber, setUserPhoneNumber] = useRecoilState(userPhoneState)
  const { login } = useAuth()

  const submitForm = () => {
    let loginDto: LoginDto = {
      id: phone,
      password: password,
    }
    mutate(loginDto, {
      onSuccess: (data: LoginSuccessVo) => {
        initLoginState(data)
        navigate('/')
      },
      onError: (error) => {
        // alert('НЭВТРЭХ АМЖИЛТГҮЙ БОЛЛОО. НУУЦ ҮГЭЭ ШАЛГАНА УУ.') /*로그인에 실패하였습니다., 비밀번호를 확인하여 주십시오.*/
        customAlert('НЭВТРЭХ АМЖИЛТГҮЙ БОЛЛОО. НУУЦ ҮГЭЭ ШАЛГАНА УУ.')
        return
      },
      onSettled: (data, error) => {},
    })
  }

  const initLoginState = (data: LoginSuccessVo) => {
    login(data)
    // setRefreshToken(data.refresh_token)
    // setAccessToken(data.access_token)
    // setUserPhoneNumber(data.phone)
    // setUserName(data.name)
    // setIsLogin(true)
    // localStorage.setItem('jwt-token', data.access_token)
    // localStorage.setItem('refresh-token', data.refresh_token)
    // localStorage.setItem('phone', data.phone)
    // localStorage.setItem('name', data.name)
    // localStorage.setItem('email', data.email)
    // localStorage.setItem('default-address', data.default_address_id + '')
  }

  const onChangeFormValue = (e: ChangeEvent<HTMLInputElement>) => {
    let eventValue = e.target.value as string
    if (e.target.id === 'id') {
      setPhone(eventValue)
    } else if (e.target.id === 'password') {
      setPassword(eventValue)
    }
  }

  return (
    <AuthLayout pageName="НЭВТРЭХ" /*로그인*/ pageHeader={<LoginHeader />}>
      <Box p={5}>
        <Text>ИМЭЙЛ ХАЯГ</Text> {/*아이디*/}
        <Input
          id="id"
          placeholder="ИМЭЙЛ ХАЯГ" /*아이디*/
          variant="filled"
          mb={3}
          bgColor={'white'}
          border={'1px solid #DFE3E9'}
          onChange={onChangeFormValue}
        />
        <Text>НУУЦ ҮГ</Text> {/*비밀번호*/}
        <Input
          id="password"
          placeholder="НУУЦ ҮГ" /*비밀번호*/
          type="password"
          variant="filled"
          mb={6}
          bgColor={'white'}
          border={'1px solid #DFE3E9'}
          onChange={onChangeFormValue}
        />
        <Flex
          justifyContent={isMobile ? 'center' : 'flex-end'}
          fontSize={16}
          color={'#252525'}
        >
          <Link to="/auth/forgot-password">ХАЯГ/НУУЦ ҮГ ХАЙХ</Link>{' '}
          {/*아이디/비밀번호 찾기*/}
          <Center w="30px">
            <Divider orientation="vertical" borderColor="gray.400" />
          </Center>
          <Link to="/auth/sign-in">БҮРТГҮҮЛЭХ</Link> {/*회원가입*/}
        </Flex>
        <Button colorScheme="blue" mt={5} onClick={submitForm} w={'100%'}>
          НЭВТРЭХ {/*로그인*/}
        </Button>
      </Box>
    </AuthLayout>
  )
}
