import { Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'

type HintTextProps = {
  hint?: string
  hintType: 'error' | 'warning' | 'info'
  preOccupySpace?: boolean
  fontSize?: string
  fontWeight?: string
}

export const HintText = ({
  hint,
  hintType,
  preOccupySpace = true,
  fontSize = 'sm',
  fontWeight = 'md',
}: HintTextProps) => {
  const color = useMemo(() => {
    switch (hintType) {
      case 'error':
        return 'red'
      case 'warning':
        return 'orange'
      case 'info':
        return 'gray'
    }
  }, [hintType])

  if (!preOccupySpace && !hint) {
    return null
  }
  return (
    <Text color={color} fontSize={fontSize} fontWeight={fontWeight}>
      {hint}
    </Text>
  )
}
