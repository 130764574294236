import { ProductModel } from 'domain/goods/model'
import { UserModel } from '../user/models'
import {
  CartResponseDTO,
  CostResponseDTO,
  OrderProductResponseDTO,
  OrderResponseDTO,
} from './response.dto'
import { OrderProductForm } from './forms'
import { OrderStatus } from 'common/util/enum'

export class CartModel {
  public id!: number
  public userId!: number
  public user?: UserModel
  public productId!: number
  public product?: ProductModel
  public count!: number
  public createdAt!: Date
  public updatedAt!: Date

  constructor({
    id,
    user_id,
    product_id,
    user,
    product,
    count,
    created_at,
    updated_at,
  }: CartResponseDTO) {
    this.id = id
    this.userId = user_id
    this.productId = product_id
    this.user = user
    this.product = product
    this.count = count
    this.createdAt = created_at
    this.updatedAt = updated_at
  }

  getCost() {
    if (!this.product) return 0
    return this.product.price * this.count
  }

  getOrderProductForm() {
    if (!this.product) return null
    return new OrderProductForm({
      productName: this.product.name,
      price: this.product.price,
      count: this.count,
      productId: this.productId,
      unit: this.product.unit || 'ширх',
    })
  }
}

export class CostModel {
  public product!: number
  public delivery!: number
  public discount!: number

  constructor({ product, delivery, discount }: CostResponseDTO) {
    this.product = +product
    this.delivery = +delivery
    this.discount = +discount
  }

  getTotalCost = () => {
    return this.product + this.delivery - this.discount
  }
}

export class OrderModel {
  id: number
  orderNumber: string
  trackingNumber: string
  userId: number
  address1: string
  address2: string
  postcode: string
  name: string
  phone: string
  productCost: number
  deliveryCost: number
  discount: number
  depositorName: string
  accountBankName: string
  accountHolder: string
  accountNumber: string
  receiptImageId?: number
  orderProducts?: OrderProductModel[]
  status?: OrderStatus
  createdAt?: Date
  updatedAt?: Date

  constructor({
    id,
    order_number,
    tracking_number,
    user_id,
    address1,
    address2,
    postcode,
    name,
    phone,
    product_cost,
    delivery_cost,
    discount,
    depositor_name,
    account_bank_name,
    account_holder,
    account_number,
    receipt_image_id,
    order_products,
    status,
    created_at,
    updated_at,
  }: OrderResponseDTO) {
    this.id = id
    ;(this.orderNumber = order_number),
      (this.trackingNumber = tracking_number),
      (this.userId = user_id)
    this.address1 = address1
    this.address2 = address2
    this.postcode = postcode
    this.name = name
    this.phone = phone
    this.productCost = product_cost
    this.deliveryCost = delivery_cost
    this.discount = discount
    this.depositorName = depositor_name
    this.accountBankName = account_bank_name
    this.accountHolder = account_holder
    this.accountNumber = account_number
    this.receiptImageId = receipt_image_id
    this.orderProducts = order_products.map(
      (order_product) => new OrderProductModel(order_product),
    )
    this.status = status
    this.createdAt = created_at
    this.updatedAt = updated_at
  }
}

export class OrderProductModel {
  id!: number
  orderId!: number
  productId!: number
  productName!: string
  count!: number
  price!: number
  unit!: string
  createdAt?: Date
  updatedAt?: Date

  constructor({
    id,
    order_id,
    product_id,
    product_name,
    price,
    unit,
    count,
    created_at,
    updated_at,
  }: OrderProductResponseDTO) {
    this.id = id
    this.orderId = order_id
    this.productId = product_id
    this.productName = product_name
    this.price = price
    this.unit = unit
    this.count = count
    this.createdAt = created_at
    this.updatedAt = updated_at
  }
}
