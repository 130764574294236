import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  HStack,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react'
import {
  useFindIdQuery,
  useFindPasswordQuery,
  useNewPasswordQuery,
} from 'common/api/domains/auth/authQuery'
import { findIdFetcher, findPasswordFetcher } from 'common/api/fetcher/auth'
import hashingString from 'common/util/hashStringUtils'
import { customAlert } from 'common/util/string-utils'
import { Profile } from 'domain/profile'
import { LoginDto, newPasswordDto } from 'domain/signIn'
import { UserModel } from 'domain/user/models'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import Swal from 'sweetalert2'
import { AuthLayout } from 'views/auth'
import { ForgotPasswordHeader } from 'views/auth/component/ForgotPasswordHeader'

export function ForgotPassword() {
  const [activeButton, setActiveButton] = useState<string>('findId')
  const [id, setId] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [submitFindPassword, setSubmitFindPassword] = useState<boolean>(false)
  const [firstInput, setFirstInput] = useState('')
  const [secondInput, setSecondInput] = useState('')
  const [thirdInput, setThirdInput] = useState('')

  const mailInputRef = useRef<HTMLInputElement>(null)
  const isMobile = useRecoilValue(clientIsMobile)
  const { mutate: findPasswordMutate } = useFindPasswordQuery()
  const { mutate: findIdMutate } = useFindIdQuery()
  const { mutate: newPasswordMutate } = useNewPasswordQuery()
  const [result, setResult] = useState<UserModel | null>(null)
  const navigate = useNavigate()
  const submit = () => {
    if (activeButton === 'findId') {
      const phoneNumber = firstInput + '-' + secondInput + '-' + thirdInput

      const resquestData = {
        phone: phoneNumber,
        name,
      }
      findIdMutate(resquestData, {
        onSuccess: (data) => {
          if (data === '') {
            // alert('Бүртгэл олдохгүй байна.') /*아이디를 찾을 수 없습니다.*/
            customAlert('Бүртгэл олдохгүй байна.')
            return
          } else {
            setSubmitFindPassword(true)
            setResult(data)
          }
        },
        onError: (error) => {},
        onSettled: (data, error) => {},
      })
    } else if (activeButton === 'findPassword') {
      const resquestData = {
        name,
        email: id,
      }

      findPasswordMutate(resquestData, {
        onSuccess: (data) => {
          if (data === '') {
            // alert('Бүртгэл олдохгүй байна.') /*아이디를 찾을 수 없습니다.*/
            customAlert('Бүртгэл олдохгүй байна.')
            return
          } else {
            setSubmitFindPassword(true)
            setResult(data)
          }
        },
        onError: (error) => {},
        onSettled: (data, error) => {},
      })
    }
  }

  const submitNewPassword = () => {
    if (password !== confirmPassword) {
      // alert(
      //   'Таны оруулсан нууц үг таарахгүй байна.',
      // ) /*입력하신 비밀번호가 일치하지 않습니다.*/
      customAlert('Таны оруулсан нууц үг таарахгүй байна.')
      return
    }
    if (password.length < 8 || password.length > 16) {
      // alert('Доод тал нь 8 тэмдэгттэй нууц үг оруулна уу.')
      customAlert('Доод тал нь 8 тэмдэгттэй нууц үг оруулна уу.')
      return
    }
    {
      /*비밀번호를 8자 이상, 16자 이하로 입력하여 주십시오.*/
    }
    let resquestData: newPasswordDto = {
      id: result?.id,
      newPassword: hashingString(password),
    }

    newPasswordMutate(resquestData, {
      onSuccess: (data) => {
        if (data.affected > 0) {
          customAlert('Таны нууц үг амжилттай солигдлоо.')

          navigate('/auth/login')
        }
      },
      onError: (error) => {},
      onSettled: (data, error) => {},
    })
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement
    if (target.id === 'findPassword') {
      setActiveButton('findPassword')
    } else {
      setActiveButton('findId')
    }
    if (submitFindPassword) {
      setSubmitFindPassword(false)
    }
  }

  return (
    <AuthLayout
      pageName="ХАЯГ/НУУЦ ҮГ ХАЙХ"
      pageHeader={<ForgotPasswordHeader />}
    >
      <FormControl alignItems="center" p={isMobile ? 2 : 5}>
        <Box mb={5} bg={'#f5f5f5'} p={1.5} py={1} borderRadius="md">
          <Button
            w="50%"
            onClick={handleButtonClick}
            colorScheme="blue"
            bg={activeButton === 'findId' ? undefined : '#f5f5f5'}
            color={activeButton === 'findId' ? 'white' : 'black'}
            _hover={{}}
            id="findId"
          >
            ХАЯГ ХАЙХ {/*아이디 찾기*/}
          </Button>
          <Button
            w="50%"
            onClick={handleButtonClick}
            colorScheme="blue"
            bg={activeButton === 'findPassword' ? undefined : '#f5f5f5'}
            color={activeButton === 'findPassword' ? 'white' : 'black'}
            _hover={{}}
            id="findPassword"
          >
            НУУЦ ҮГ ХАЙХ {/*비밀번호찾기*/}
          </Button>
        </Box>
        {!submitFindPassword ? (
          <>
            <Text>НЭР*</Text> {/*이름*/}
            <Input value={name} onChange={(e) => setName(e.target.value)} />
            <Text fontSize={'14px'} color={'#606060'}>
              Нэрээ оруулна уу. {/*이름을 입력해주세요.*/}
            </Text>
            <Text mt={5}>
              {activeButton === 'findId' ? 'УТАС*' : 'ИМЭЙЛ*'}{' '}
              {/* 핸드폰/이메일*/}
            </Text>
            {activeButton === 'findId' ? (
              <>
                <HStack spacing={2}>
                  <Input
                    value={firstInput}
                    onChange={(e) => setFirstInput(e.target.value)}
                    maxLength={3}
                    type="tel"
                  />
                  <Text>-</Text>
                  <Input
                    value={secondInput}
                    onChange={(e) => setSecondInput(e.target.value)}
                    maxLength={4}
                    type="tel"
                  />
                  <Text>-</Text>
                  <Input
                    value={thirdInput}
                    onChange={(e) => setThirdInput(e.target.value)}
                    maxLength={4}
                    type="tel"
                  />
                </HStack>
                <Text fontSize={'14px'} color={'#606060'}>
                  Утасны дугаараа оруулна уу.
                </Text>{' '}
                {/*전화번호를 입력해주세요.*/}
              </>
            ) : (
              <>
                <Input
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  type="email"
                />
                <Text fontSize={'14px'} color={'#606060'}>
                  Имэйлээ оруулна уу.
                </Text>{' '}
                {/*이메일을 입력해주세요.*/}
              </>
            )}
          </>
        ) : (
          <>
            {activeButton === 'findId' ? (
              <>
                <>
                  <Text textAlign={'center'}>
                    ТАНЫ БҮРТГЭЛ АМЖИЛТТАЙ ОЛДЛОО.
                  </Text>{' '}
                  {/*아이디를 성공적으로 찾았습니다.*/}
                  <Flex ml={isMobile ? 2 : 5} pt={5}>
                    <Text as="samp" w={isMobile ? '40%' : '35%'}>
                      ИМЭЙЛ
                    </Text>{' '}
                    {/*이메일(아이디)*/}
                    <Text w={isMobile ? '60%' : '65%'}>{[result?.email]}</Text>
                  </Flex>
                  <Divider pt={2} />
                  <Flex ml={isMobile ? 2 : 5} pt={5}>
                    <Text as="samp" w={isMobile ? '40%' : '35%'}>
                      НЭР
                    </Text>{' '}
                    {/*이름*/}
                    <Text w={isMobile ? '60%' : '65%'}>{[result?.name]}</Text>
                  </Flex>
                  <Divider pt={2} />
                </>
              </>
            ) : (
              <>
                <>
                  <Text>ШИНЭ НУУЦ ҮГ*</Text> {/*새비밀번호*/}
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Text mt={5}>НУУЦ ҮГ ДАХИН БИЧИХ*</Text> {/*새비밀번호확인*/}
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </>
              </>
            )}
          </>
        )}
        <Button
          w="100%"
          colorScheme="blue"
          mt={10}
          onClick={
            submitFindPassword
              ? activeButton === 'findId'
                ? () => navigate('/auth/login')
                : submitNewPassword
              : submit
          }
        >
          {submitFindPassword
            ? activeButton === 'findId'
              ? 'ШАЛГАХ'
              : 'ОРЛУУЛАХ'
            : 'ШАЛГАХ'}
        </Button>
      </FormControl>
    </AuthLayout>
  )
}
