import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthLayout, LoginInfo, SignIn, SignOut } from 'views/auth'
import {
  DeliveryAddressManagement,
  DeliveryAddressRegister,
  ForgotPassword,
  OrderList,
  ProfileInfo,
} from 'views/profile'
import { ModifyProfile } from 'views/profile/ModifyProfile'
import { ProductList, CategoryList } from 'views/goods'
import { ProductDetailPage } from 'pages/ProductDetailPage'
import { DetailOrderInfo } from 'views/profile/DetailOrderInfo'
import { TermsOfUse } from 'views/component/TermsOfUse'
import { PrivacyPolicy } from 'views/component/PrivacyPolicy'

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<CategoryList />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route
        path="/auth/*"
        element={
          <Routes>
            <Route path="/login" element={<LoginInfo />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/sign-out" element={<SignOut />} />
          </Routes>
        }
      />

      <Route
        path="/goods/*"
        element={
          <Routes>
            <Route path="/categories" element={<CategoryList />} />
            <Route
              path="/categories/:categoryId/products"
              element={<ProductList />}
            />
            <Route
              path="/products/:productId"
              element={<ProductDetailPage />}
            />
          </Routes>
        }
      />
    </Routes>
  )
}
