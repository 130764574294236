import {
  Divider,
  Box,
  Text,
  HStack,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BasicAddress } from './options/BasicAddress'
import { ManualAddress } from './options/ManualAddress'
import { ImageAddress } from './options/ImageAddress'
import { AddressForm } from 'common/hooks/useAddress'
import { useFormikContext } from 'formik'
import { OrderFomikValuesType } from '../../type'

type DeliveryAddressProps = {}

export const DeliveryAddress = ({}: DeliveryAddressProps) => {
  const { setFieldValue } = useFormikContext<OrderFomikValuesType>()
  // 0 ~ 2 [3개]
  const [option, setOption] = useState(0)

  const buttons = [
    'БАЙНГЫН ХАЯГ',
    'ХАЯГ ХАЙХ',
    'ЗУРАГ ХАЙЛТ',
  ] /*기본주소,직접검색,사진검색*/

  const handleAddress = (addressForm: AddressForm) => {
    setFieldValue('address', addressForm)
  }

  return (
    <Box>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        ХАЯГ
      </Text>{' '}
      {/*배송지*/}
      <Divider borderWidth={2} borderColor={'gray'} />
      <Tabs variant="unstyled">
        <TabList>
          {buttons.map((text, index) => {
            return (
              <Tab>
                <Button
                  key={index}
                  variant={'outline'}
                  borderRadius={32}
                  w={'180px'}
                  h={'55px'}
                  onClick={() => setOption(index)}
                  backgroundColor={option === index ? '#606060' : 'white'}
                  color={option === index ? 'white' : 'gray'}
                  _hover={{}}
                >
                  {text}
                </Button>
              </Tab>
            )
          })}
        </TabList>
        <TabPanels>
          <TabPanel>
            <BasicAddress
              handleAddress={handleAddress}
              isActive={option === 0}
            />
          </TabPanel>
          <TabPanel>
            <ManualAddress
              handleAddress={handleAddress}
              isActive={option === 1}
            />
          </TabPanel>
          <TabPanel>
            <ImageAddress
              handleAddress={handleAddress}
              isActive={option === 2}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Divider />
    </Box>
  )
}
