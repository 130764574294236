import axios from 'axios'
import { IUserInfo } from '../../../../domain/test/userList'
import { FindDto, LoginDto, newPasswordDto, SignInDto } from 'domain/signIn'
import { createAxiosInstance } from 'common/config/axios.config'

const axiosInstance = createAxiosInstance(false)

export function signInFetcher(signInData: SignInDto) {
  return axiosInstance.post(`/profile/sign-in`, signInData).then((response) => {
    return response.data
  })
}

export function loginFetcher(loginDto: LoginDto) {
  return axios.post(`/auth/login`, loginDto).then((response) => {
    return response.data
  })
}
export function signOutFetcher() {
  return axiosInstance.put(`/profile/sign-out`).then((response) => {
    return response.data
  })
}
export function logoutFetcher(data: boolean) {
  return axiosInstance.post(`/auth/logout`).then((response) => {
    return response.data
  })
}
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.headers['Content-Type'] = 'application/json'
export function findPasswordFetcher(data: FindDto) {
  return axiosInstance.post(`/profile/find/password`, data).then((response) => {
    return response.data
  })
}
export function findIdFetcher(data: FindDto) {
  return axiosInstance.post(`/profile/find/id`, data).then((response) => {
    return response.data
  })
}

export function newPasswordFetcher(data: newPasswordDto) {
  return axiosInstance.put(`/profile/password`, data).then((response) => {
    return response.data
  })
}
