import { NavigateFunction, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export function isEmpty(text: any) {
  return text === null || text === undefined || text === ''
}

export function formatDateString(isoDateString: Date) {
  const date = new Date(isoDateString)

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  // const hours = String(date.getHours()).padStart(2, '0')
  // const minutes = String(date.getMinutes()).padStart(2, '0')
  // const seconds = String(date.getSeconds()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  return formattedDate
}

export function isLogin(navigate: NavigateFunction) {
  if (
    isEmpty(localStorage.getItem('jwt-token')) ||
    isEmpty(localStorage.getItem('refresh-token')) ||
    isEmpty(localStorage.getItem('user')) ||
    isEmpty(localStorage.getItem('phone'))
  ) {
    navigate('/')
  }
}

export function customAlert(message: string) {
  Swal.fire({
    text: message,
    cancelButtonAriaLabel: '3A',
    buttonsStyling: true,
    position: 'top',
    customClass: {
      container: 'custom-container',
    },
  })
}
