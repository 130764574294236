import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Link,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { log } from 'console'
import { useOrderListQuery } from 'common/api/domains/profile/profileQuery'
import { OrderStatus, OrderStatusKorean } from 'common/util/enum'
import { OrderModel } from 'domain/purchase/models'
import { useNavigate } from 'react-router-dom'
import { useGetOrder } from 'common/api/domains/purchase/purchaseQuery'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { OrderReceiptBase } from './OrderReceiptBase'
import './css/OrderListPanel.scss'
const selectedStyle = {
  fontWeight: 'bold',
  color: 'black',
  borderTop: '2px solid black',
  borderBottom: 'none',
}
const inputStyle = {
  fontSize: '11px',
  height: '32px',
  padding: '5px',
}
type orderListType = {
  panelType: string
}

export function OrderListPanel({ panelType }: orderListType) {
  const isMobile = useRecoilValue(clientIsMobile)

  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
  )
  const [endDate, setEndDate] = useState(new Date(Date.now()).toISOString())
  const [activeButton, setActiveButton] = useState(0)
  const [_startDate, _setStartDate] = useState(
    new Date(Date.now()).toISOString(),
  )
  const [_endDate, _setEndDate] = useState(new Date(Date.now()).toISOString())

  const { data, isLoading, error, refetch } = useOrderListQuery(
    startDate,
    endDate,
  )
  const searchData = () => {
    setStartDate(_startDate)
    setEndDate(_endDate)
    setActiveButton(4)
  }

  const setXMonthAgo = (pointInTime: number) => {
    setActiveButton(pointInTime)
    const now = new Date()
    now.setMonth(now.getMonth() - pointInTime)
    if (pointInTime === 0) {
      now.setDate(now.getDate() - 1)
    }
    const oneMonthAgoISOString = now.toISOString()
    setFindOption(oneMonthAgoISOString, new Date(Date.now()).toISOString())
  }

  const setFindOption = (start: string, end: string) => {
    setStartDate(formatDate(start))
    setEndDate(formatDate(end))
  }
  const showDetailOrderInfo = (order: OrderModel) => {
    navigate('/management/profile/order/detail', { state: order })
  }
  const viewFormat = (dateStromg: Date) => {
    const date = new Date(dateStromg.toString())
    if (date === null || date === undefined) {
      return ''
    }
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // 월은 0부터 시작하므로 +1을 해줍니다
    const day = ('0' + date.getDate()).slice(-2)
    // const hours = ('0' + date.getHours()).slice(-2)
    // const minutes = ('0' + date.getMinutes()).slice(-2)
    // const seconds = ('0' + date.getSeconds()).slice(-2)

    // 원하는 형식으로 문자열을 반환합니다
    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    return `${year}-${month}-${day}`
  }

  const formatDate = (stringdate: string) => {
    let date = new Date(stringdate)
    const offset = date.getTimezoneOffset()
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000)
    return adjustedDate.toISOString().slice(0, 16)
  }

  const changeStartDate = (e: ChangeEvent<HTMLInputElement>, type: boolean) => {
    const date = new Date(e.target.value)
    type
      ? _setStartDate(date.toISOString().slice(0, 16))
      : _setEndDate(date.toISOString().slice(0, 16))
  }

  useEffect(() => {
    refetch()
  }, [startDate, endDate])
  return (
    <TabPanel w={isMobile ? '375px' : '800px'}>
      <Box className="gray-bg" p={3} borderRadius={4}>
        {isMobile ? (
          <>
            <Box
              justifyContent={'center'}
              display={'flex'}
              mt={3}
              mb={6}
            >
              <Button
                colorScheme={activeButton === 0 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'40%'}
                onClick={(e) => setXMonthAgo(0)}
              >
                ӨНӨӨДӨР
              </Button>{' '}
              {/*오늘*/}
              <Button
                colorScheme={activeButton === 1 ? 'white ' : 'gray'}
                variant="outline"
                borderRadius={4}
                bg={'white'}
                size={'sm'}
                w={'40%'}
                onClick={(e) => setXMonthAgo(1)}
              >
                1 САР
              </Button>{' '}
              {/*1개월*/}
              <Button
                colorScheme={activeButton === 3 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'40%'}
                onClick={(e) => setXMonthAgo(3)}
              >
                3 САР
              </Button>{' '}
              {/*3개월*/}
              <Button
                colorScheme={activeButton === 6 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'40%'}
                onClick={() => setXMonthAgo(6)}
              >
                6 САР
              </Button>{' '}
              {/*6개월*/}
            </Box>
            <Flex w={'100%'} alignItems="center" gap={2} mt={2}>
              <Input
                type="datetime-local"
                bg={'white'}
                value={formatDate(_startDate)}
                style={{ ...inputStyle }}
                onChange={(e) => changeStartDate(e, true)}
              />
              <Divider
                borderColor="black"
                w={4}
                alignSelf={'center'}
                fontSize={10}
              />
              <Input
                type="datetime-local"
                bg={'white'}
                value={formatDate(_endDate)}
                style={{ ...inputStyle }}
                onChange={(e) => changeStartDate(e, false)}
              />
            </Flex>
            <Flex w={'100%'} alignItems="center" gap={2} mt={7} mb={3}>
              <Button
                colorScheme="blackAlpha"
                size="sm"
                // border={activeButton === 4 ? 1 : 0}
                border={activeButton === 4 ? '1px solid black' : ''}
                borderRadius={4}
                onClick={searchData}
                w={'100%'}
              >
                ХАЙХ
              </Button>{' '}
              {/*검색*/}
            </Flex>
          </>
        ) : (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={3}
            mx={2}
          >
            <Box w={'50%'}>
              <Button
                colorScheme={activeButton === 0 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'25%'}
                fontSize={12}
                onClick={(e) => setXMonthAgo(0)}
              >
                ӨНӨӨДӨР
              </Button>{' '}
              {/*오늘*/}
              <Button
                colorScheme={activeButton === 1 ? 'white ' : 'gray'}
                variant="outline"
                borderRadius={4}
                bg={'white'}
                size={'sm'}
                w={'20%'}
                fontSize={12}
                onClick={(e) => setXMonthAgo(1)}
              >
                1 САР
              </Button>{' '}
              {/*오늘*/}
              <Button
                colorScheme={activeButton === 3 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'20%'}
                fontSize={12}
                onClick={(e) => setXMonthAgo(3)}
              >
                3 САР
              </Button>{' '}
              {/*오늘*/}
              <Button
                colorScheme={activeButton === 6 ? 'white ' : 'gray'}
                variant="outline"
                bg={'white'}
                borderRadius={4}
                size={'sm'}
                w={'20%'}
                fontSize={12}
                onClick={() => setXMonthAgo(6)}
              >
                6 САР
              </Button>{' '}
              {/*오늘*/}
            </Box>
            <Flex w={'60%'} alignItems="center" gap={2} mx={5}>
              <Input
                type="datetime-local"
                bg={'white'}
                value={formatDate(_startDate)}
                style={{ ...inputStyle }}
                onChange={(e) => changeStartDate(e, true)}
                fontWeight={600}
                textAlign={'center'}
              />
              <Divider
                borderColor="black"
                w={4}
                alignSelf={'center'}
                fontSize={10}
              />
              <Input
                type="datetime-local"
                bg={'white'}
                value={formatDate(_endDate)}
                style={{ ...inputStyle }}
                onChange={(e) => changeStartDate(e, false)}
                fontWeight={600}
                textAlign={'center'}
              />
              <Button
                color={'#f7f7f7'}
                bg={'#242424'}
                size="sm"
                // border={activeButton === 4 ? 1 : 0}
                border={activeButton === 4 ? '1px solid black' : ''}
                borderRadius={4}
                onClick={searchData}
                w={'25%'}
              >
                ХАЙХ
              </Button>{' '}
              {/*검색*/}
            </Flex>
          </Flex>
        )}
      </Box>
      <Box my={5}>
        <Divider borderColor="gray.400" mt={3} />
        {isMobile ? (
          <>
            {isMobile && data && Array.isArray(data) ? (
              <>
                {data
                  .filter((order) =>
                    panelType === 'cancelList'
                      ? order.status === 7 ||
                        order.status === 8 ||
                        order.status === 9
                      : true,
                  )
                  .map((order) => (
                    // <Tr key={order.id}>
                    <>
                      <Flex
                        justifyContent={'space-between'}
                        p={3}
                        mt={6}
                        bgColor={'white'}
                        border={'1px solid black'}
                        borderRadius={0}
                      >
                        <Text fontSize={14} fontWeight={600}>
                          <>
                            {order.createdAt ? viewFormat(order.createdAt) : ''}{' '}
                            ({order.orderNumber})
                          </>
                        </Text>
                        <Link
                          display="block"
                          onClick={() => showDetailOrderInfo(order)}
                          fontSize={14}
                          fontWeight={600}
                        >
                          ДЭЛГЭРЭНГҮЙ &gt;
                        </Link>
                      </Flex>
                      {order.orderProducts && Array.isArray(order.orderProducts)
                        ? order.orderProducts.map((prod) => (
                            <>
                              <Box
                                px={7}
                                pt={2}
                                pb={4}
                                borderX={'1px solid black'}
                              >
                                <Text display={'flex'}>{prod.productName}</Text>
                                <Text display={'flex'}>
                                  ₩{prod.price}({prod.count})
                                </Text>
                              </Box>
                            </>
                          ))
                        : ''}
                      <Flex
                        justifyContent={'space-between'}
                        p={3}
                        bgColor={'#292929'}
                        color={'white'}
                        borderRadius={4}
                      >
                        <Text>НИЙТ ҮНЭ (ШИРХЭГ)</Text> {/*총 금액(수량)*/}
                        {order.orderProducts &&
                        Array.isArray(order.orderProducts) ? (
                          <Flex
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Text>
                              ₩
                              {order.orderProducts.reduce(
                                (total, prod) =>
                                  total + (prod.price || 0) * (prod.count || 0),
                                0,
                              )}
                              (
                              {order.orderProducts.reduce(
                                (total, prod) => total + (prod.count || 0),
                                0,
                              )}
                              )
                            </Text>
                            {/* <OrderReceiptBase orderId={order.id} /> */}
                          </Flex>
                        ) : (
                          ''
                        )}
                      </Flex>
                      {/* <Text display={'flex'}>
                          <>
                            {order && order.status
                              ? OrderStatusKorean[order.status]
                              : ''}
                          </>
                        </Text> */}
                      {/* <Tr>
                          {order.orderProducts &&
                          Array.isArray(order.orderProducts)
                            ? order.orderProducts.map((prod) => (
                                <Text display={'flex'}>{prod.productName}</Text>
                              ))
                            : ''}
                        </Tr>
                        <Tr>
                          {order.orderProducts &&
                          Array.isArray(order.orderProducts)
                            ? order.orderProducts.map((prod) => (
                                <Text display={'flex'}>
                                  ₩{prod.price}({prod.count})
                                </Text>
                              ))
                            : ''}
                        </Tr>
                        <Tr>
                          <Text>
                            {order.orderProducts &&
                            Array.isArray(order.orderProducts) ? (
                              <Text display={'flex'}>
                                ₩
                                {order.orderProducts.reduce(
                                  (total, prod) =>
                                    total +
                                    (prod.price || 0) * (prod.count || 0),
                                  0,
                                )}
                                (
                                {order.orderProducts.reduce(
                                  (total, prod) => total + (prod.count || 0),
                                  0,
                                )}
                                )
                              </Text>
                            ) : (
                              ''
                            )}
                          </Text>
                        </Tr>
                        <Tr textAlign={'center'}>
                          {panelType === 'orderList' ? (
                            <OrderReceiptBase orderId={order.id} />
                          ) : (
                            <></>
                          )}
                        </Tr> */}
                      {/* </> </Tr> */}
                    </>
                  ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <TableContainer>
            <Table size="sm">
              <Thead textAlign={'center'}>
                <Tr className="order-list-header">
                  <Th>ӨДӨР[ No.]</Th> {/*주문일자[주문번호]*/}
                  <Th>БҮТЭЭГДЭХҮҮН</Th> {/*상품명*/}
                  <Th>ҮНЭ / ШИРХЭГ</Th> {/*총금액/수량*/}
                  <Th>НИЙТ ҮНЭ / ШИРХЭГ</Th> {/*총금액/수량*/}
                  <Th textAlign={'center'}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data && Array.isArray(data) ? (
                  <>
                    {data
                      .filter((order) =>
                        panelType === 'cancelList'
                          ? order.status === 7 ||
                            order.status === 8 ||
                            order.status === 9
                          : true,
                      )
                      .map((order) => (
                        <Tr key={order.id}>
                          <Td>
                            <Text display={'flex'}>
                              <>
                                {order.createdAt
                                  ? viewFormat(order.createdAt)
                                  : ''}
                                ({order.orderNumber})
                              </>
                            </Text>
                            <Text display={'flex'}>
                              <>
                                {order && order.status
                                  ? OrderStatusKorean[order.status]
                                  : ''}
                              </>
                            </Text>
                          </Td>
                          <Td>
                            {order.orderProducts &&
                            Array.isArray(order.orderProducts)
                              ? order.orderProducts.map((prod) => (
                                  <Text display={'flex'}>
                                    {prod.productName}
                                  </Text>
                                ))
                              : ''}
                          </Td>
                          <Td>
                            {order.orderProducts &&
                            Array.isArray(order.orderProducts)
                              ? order.orderProducts.map((prod) => (
                                  <Text display={'flex'}>
                                    ₩{prod.price.toLocaleString('en-us')}(
                                    {prod.count.toLocaleString('en-us')})
                                  </Text>
                                ))
                              : ''}
                          </Td>
                          <Td>
                            <Text>
                              {order.orderProducts &&
                              Array.isArray(order.orderProducts) ? (
                                <Text display={'flex'}>
                                  ₩
                                  {order.orderProducts
                                    .reduce(
                                      (total, prod) =>
                                        total +
                                        (prod.price || 0) * (prod.count || 0),
                                      0,
                                    )
                                    .toLocaleString('en-us')}
                                  (
                                  {order.orderProducts
                                    .reduce(
                                      (total, prod) =>
                                        total + (prod.count || 0),
                                      0,
                                    )
                                    .toLocaleString('en-us')}
                                  )
                                </Text>
                              ) : (
                                ''
                              )}
                            </Text>
                          </Td>
                          <Td textAlign={'center'}>
                            <Link
                              display={'block'}
                              onClick={() => showDetailOrderInfo(order)}
                            >
                              ДЭЛГЭРЭНГҮЙ &gt;
                            </Link>{' '}
                            {/*자세히*/}
                            {/* {panelType === 'orderList' ? (
                              <OrderReceiptBase orderId={order.id} />
                            ) : (
                              <></>
                            )} */}
                          </Td>
                        </Tr>
                      ))}
                  </>
                ) : (
                  <></>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </TabPanel>
  )
}
