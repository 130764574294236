import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { OrderProductForm } from 'domain/purchase/forms'
import React from 'react'
import { MoneyText } from 'views/component/MoneyText'
import { useSetRecoilState } from 'recoil'
import { orderProductFormsState } from 'state/purchase/atoms'

type OrderItemProps = {
  orderProductForm: OrderProductForm
}

export const OrderItem = ({
  orderProductForm: { productId, productName, count, price, unit },
}: OrderItemProps) => {
  const setOrderProductForms = useSetRecoilState(orderProductFormsState)

  const onClickDelete = () => {
    setOrderProductForms((prev) =>
      prev.filter((form) => form.productId !== productId),
    )
  }

  return (
    <Flex mt={4}>
      <Box flex={1}>
        <Flex justifyContent={'space-between'} alignItems={'center'} mb={4}>
          <Text>{`${productName}`}</Text>
          <Button onClick={onClickDelete} variant={'link'}>
            <CloseIcon />
          </Button>
        </Flex>
        <Flex>
          <MoneyText amount={price} unit={'₩'} textProps={{ mb: 4 }} />
          <Text ml={2}>{`/ ширхэг ${count} ${unit}`}</Text> {/*수량*/}
        </Flex>
        <Divider />
      </Box>
    </Flex>
  )
}
