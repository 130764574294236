import { OrderProductForm } from 'domain/purchase/forms'
import { CartModel, CostModel } from 'domain/purchase/models'
import { atom } from 'recoil'

export const purchaseState = atom<number>({
  key: 'purchaseState',
  default: 0,
})

export const cartsState = atom<{ [cartId: number]: CartModel }>({
  key: 'cartsState',
  default: {},
})

export const selectedCartIdsState = atom<number[]>({
  key: 'selectedCartIdsState',
  default: [],
})

export const cartCostState = atom<CostModel>({
  key: 'cartCostState',
  default: new CostModel({ product: 0, delivery: 0, discount: 0 }),
})

export const orderProductFormsState = atom<OrderProductForm[]>({
  key: 'orderProductFormsState',
  default: [],
})

export const isOrderFromCartState = atom<boolean>({
  key: 'isOrderFromCartState',
  default: false,
})
